<template>
  <section class="mt-16">
    <v-row justify="center" class="mb-8">
      <v-col cols="12" sm="6" md="4" align="center">
        <v-img src="../../assets/img/comprasal_login.png"/>
      </v-col>
    </v-row>
    <h3 class="text-center">No puedes participar por el momento debido a que posees sanciones vigentes</h3>
  </section>
</template>

<script>
import LottieAnimation from "lottie-web-vue";
export default {
  components: {
    LottieAnimation,
  },
  methods: {},
  created() {},
};
</script>
